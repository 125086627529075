import { Line } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
ChartJS.register(...registerables, ChartDataLabels);

// ChartJS.defaults.scale.grid. = false;

export const LineChart = ({ labels, dataSet }: any) => {
    const data = {
        labels: labels,
        datasets: [
            {
                label: "First dataset",
                data: factorData(dataSet),
                lineTension: 0,
                spanGaps: true,
                backgroundColor: "rgba(75,192,192,0.2)",
                borderColor: "grey",
                borderWidth: 1
            },

        ]
    };
    function factorData(data: any) {
        let _data = data.map((e: any, i: any, a: any) => {
            let prev = a[i - 1];
            let next = a[i + 1];
            if (e === prev && e === next) return '' + e;
            return e;
        }).map((e: any) => typeof e === 'string' ? null : e);
        return _data;
    }

    const options = {
        responsive: true,
        layout: {
            padding: {
                // Any unspecified dimensions are assumed to be 0                     
                top: 25
            }
        },

        plugins: {
            legend: {
                display: false,
                intersect: false,
                // labels: {
                //     usePointStyle: true,
                // },
            },
            tooltip: {
                // mode: "x" as const,
                // yAlign: 'top' as const,
                enabled: false,
                //intersect: false,
            },
            datalabels: {
                anchor: 'end' as const,
                align: 'top' as const,
                formatter: function (value: any, context: any) {
                    return Math.round(value) + " lacs";
                },
                borderRadius: 4,
                //color: 'yellow' as const,

                font: {
                    weight: 'normal' as const,
                    size: 12
                },
                // formatter: Math.round,
                padding: 6
            },
            // datalabels: {
            //     backgroundColor: function (context: any) {
            //         return context.dataset.backgroundColor;
            //     },
            //     borderRadius: 4,
            //     color: 'white',
            //     font: {
            //         weight: 'bold'
            //     },
            //     formatter: Math.round,
            //     padding: 6
            // }
        },
        scales: {
            y: {
                beginAtZero: true,
                stacked: true,
                display: false,
                grid: {
                    display: false
                },
                border: {
                    display: false

                }
            },
            x: {
                border: {
                    display: false
                }
            }
        },

        elements: {
            point: {
                pointStyle: 'circle'
            },

        }
    }

    return (
        <Line data={data} options={options} className="m-5" />
    );
}