import React, { useMemo } from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CustomButton from '../../../../common/ui/CustomButton';
import VerifiedText from '../../MobileNumber/VerifiedText';
import UnverifiedText from '../../MobileNumber/UnverifiedText';
import { useSelector } from 'react-redux';
import { selectTotalPolices } from '../Bank.slice';

const BankDetailsHeader = ({
	data,
	index,
	onRemoveClick,
	isUnVerified = false,
	onVerifyNow,
	showAssignButton,
	handleAssignPolicy,
	unassigned = false,
}: any) => {
	const theme = useTheme();
	const { t } = useTranslation();
	const policiesForBank = useSelector(selectTotalPolices);


	const filterPolicy = useMemo(() =>
		policiesForBank.filter((item: any) => item.bankAcNo !== (data?.accountNo || data?.bankAcNo))
		, [policiesForBank]);

	const atleastOneIntegPoliciesHave = useMemo(() =>
		filterPolicy.some((item: any) => item.insIntegFlag === 'Y')
		, [filterPolicy]);

	const anyIntegPolicies = useMemo(() =>
		policiesForBank.some((item: any) => item.insIntegFlag === 'Y')
		, [policiesForBank]);
	return (
		<div>
			<Grid
				container
				justifyContent="space-between"
				alignItems="center"
				wrap="nowrap"
				className="mb-10"
			>
				<Grid item className="ml-n17" flex={1}>
					<Grid container spacing={0.5}>
						<Grid item>
							<Typography color="black" fontSize={20} fontWeight={600}>
								{index + 1}.
							</Typography>
						</Grid>
						<Grid item className="mt-5" flex={1}>
							<Typography color="primary" fontSize={16} fontWeight={600}>
								{data.bankName}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
				<Grid item>
					{!isUnVerified ? (
						<VerifiedText />
					) : (
						<UnverifiedText />
					)}
				</Grid>
				<Grid item>
					<CustomButton
						text={t('REMOVE')}
						variant="text"
						fontSize={14}
						fontWeight={400}
						showIcon={false}
						onClick={onRemoveClick}
						color="primary"
					/>
				</Grid>
			</Grid>
			<Grid
				flexWrap="nowrap"
				container
				justifyContent="space-between"
				className="mb-15 "
				spacing={0.7}
			>
				<Grid item className="" flex={1}>
					<Grid item>
						<Typography className="f-16" color={theme.palette.common.black}>
							{data.accountHolderName}
						</Typography>

						<Typography className="f-16" color={theme.palette.common.black}>
							{t('ACC')}# {data.accountNo || data.bankAcNo}
						</Typography>
					</Grid>
					<Grid item>
						<Typography className="f-16" color={theme.palette.common.black}>
							{data.bankName}
						</Typography>
						<Typography className="f-16" color={theme.palette.common.black}>
							{data.bankBranch}
						</Typography>
					</Grid>
					<Grid item>
						<Typography className="f-16" color={theme.palette.common.black}>
							{data.bankIfscCode || data?.ifscCode}
						</Typography>
					</Grid>
				</Grid>
				<Grid item flex={0}>
					<Grid
						className="h-100"
						container
						flexDirection="column"
						justifyContent="space-between"
						flexWrap="nowrap"
						alignItems="flex-end"
					>
						<Grid item className="mb-15">
							{data?.bankLogo && (
								<img
									src={data?.bankLogo}
									alt="Bank Logo"
									width={60}
									height={60}
									style={{ objectFit: 'contain', border: '1px solid #d7d7d7' }}
								/>
							)}
						</Grid>
						<Grid
							item
							display={unassigned ? 'flex' : undefined}
							justifyContent={unassigned ? 'flex-end' : undefined}
						>
							<Grid item className="">
								{data?.chequeImage && (
									<img
										src={`data:image/png/jpg/jpeg;base64,${data?.chequeImage}`}
										alt="Canceled Cheque"
										width="51"
										height="32"
									/>
								)}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			{isUnVerified === false && showAssignButton ? (
				<CustomButton
					text={t('ASSIGN_POLICIES')}
					variant="text"
					fontSize={14}
					fontWeight={400}
					showIcon={false}
					onClick={handleAssignPolicy}
					color="primary"
					disabled={unassigned ? !anyIntegPolicies && process.env.REACT_APP_MODE !== 'PROD' : !atleastOneIntegPoliciesHave && process.env.REACT_APP_MODE !== 'PROD'}
				/>
			) : (
				<CustomButton
					text={t('VERIFY_NOW')}
					variant="text"
					fontSize={14}
					fontWeight={400}
					showIcon={false}
					onClick={onVerifyNow}
					color="primary"
				/>
			)}
			{
				process.env.REACT_APP_MODE !== 'PROD' && isUnVerified === false && !atleastOneIntegPoliciesHave && !anyIntegPolicies && policiesForBank?.length !== 0 &&
				<Grid container>
					<Typography className='f-12' color={theme.palette.grey[400]}>This service is not currently available for your insurer’s policy(s).</Typography>
				</Grid>
			}
			{/* {showAssignButton && (
				<CustomButton
					text={t('ASSIGN_POLICIES')}
					variant="text"
					fontSize={14}
					fontWeight={400}
					showIcon={false}
					onClick={handleAssignPolicy}
					color="primary"
					disabled={unassigned ? !anyIntegPolicies : !atleastOneIntegPoliciesHave}
				/>
			)} */}
		</div>
	);
};

export default BankDetailsHeader;
