/* eslint-disable no-unsafe-optional-chaining */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import { filter, propEq } from 'ramda';
import AddressSection from '../AddressSection';
import CustomButton from '../../../../common/ui/CustomButton';
import PolicyCard from '../../Common/PolicyCard';
import { PolicyAlternateCodes } from '../../../../common/types/PolicyTypes';
import theme from '../../../../common/theme';
import { useSelector } from 'react-redux';
import { fetchAddressFromPolicy, selectPoliciesForAddress } from '../Address.slice';
import { AddressPolicyLock } from '../../../../common/utils';

const CorrespondenceAddress = ({
	addressDetails,
	index,
	policies,
	handleOnChangeAddressClick,
	toggleRemoveNumberModal,
	handleAssignPolicies,
	fullAddress
}: any) => {
	const lifePolicies = filter(
		propEq(PolicyAlternateCodes.LIFE, 'alternateCode')
	);
	const healthPolicies: any = filter(
		propEq(PolicyAlternateCodes.HEALTH, 'alternateCode')
	);
	const assetPolicies: any = filter(
		propEq(PolicyAlternateCodes.ASSET, 'alternateCode')
	);

	const { t } = useTranslation();

	const policiesForAddress = useSelector(selectPoliciesForAddress)?.addressBasedPolicies;


	const filterPolicy = useMemo(() =>
		policiesForAddress.filter((item: any) => item.fullAddress !== fullAddress)
		, [policiesForAddress]);

	const atleastOneIntegPoliciesHave = useMemo(() =>
		filterPolicy.some((item: any) => item.insIntegFlag === 'Y')
		, [filterPolicy]);

	const anyIntegPolicies = useMemo(() =>
		policiesForAddress.some((item: any) => item.insIntegFlag === 'Y')
		, [policiesForAddress]);

	return (
		<Grid item className="Manage-bank">
			<Grid
				container
				justifyContent="space-between"
				flexWrap="nowrap"
				className="mb-5"
				spacing={1}
			>
				<Grid item className="ml-n20" flex={1}>
					<Grid container spacing={0.6} flexWrap="nowrap">
						<Grid item>
							<Typography color="black" fontSize={20} fontWeight={600}>
								{index + 1}.
							</Typography>
						</Grid>
						<Grid item className="mt-3">
							<Typography color="primary" fontSize={16} fontWeight={600}>
								{addressDetails.city}

								<Typography
									component="span"
									color={theme.palette.primary.dark}
									marginLeft={1}
									className="fw-600"
								>
									{t('ADDRESS')}
								</Typography>
							</Typography>
						</Grid>
					</Grid>
				</Grid>
				<Grid item>
					<CustomButton
						text={t('REMOVE')}
						variant="text"
						color="primary"
						fontSize={14}
						showIcon={false}
						fontWeight={400}
						onClick={() =>
							toggleRemoveNumberModal({
								...addressDetails,
							})
						}
					/>
				</Grid>
			</Grid>
			<Grid item>
				<AddressSection data={addressDetails} />
			</Grid>
			<Grid item className="mt-3 ml-n3">
				<CustomButton
					text={t('ASSIGN_POLICIES')}
					variant="text"
					color="primary"
					fontSize={14}
					showIcon={false}
					fontWeight={400}
					onClick={() =>
						handleAssignPolicies(fetchAddressFromPolicy(addressDetails))
					}
					disabled={!atleastOneIntegPoliciesHave && process.env.REACT_APP_MODE !== 'PROD'}
				/>
			</Grid>
			<Grid item container display="flex" alignItems="center">
				<Grid item width={290}  >
					{process.env.REACT_APP_MODE !== 'PROD' && !atleastOneIntegPoliciesHave && !anyIntegPolicies && policiesForAddress?.length !== 0 &&
						<Typography className='f-12' color={theme.palette.grey[400]}>This service is not currently available for your insurer’s policy(s).</Typography>
					}</Grid>
			</Grid>
			{policies?.length > 0 ? (
				<Grid item className="Policies-screen p-20 mt-10">
					{lifePolicies(policies).map((policy: any, index: number) => (
						<PolicyCard
							className={index < policies?.length - 1 ? 'mb-20' : ''}
							key={policy.policyNo}
							policyDetails={policy}
							changeButtonText="CHANGE_ADDRESS"
							onChangeClick={() => handleOnChangeAddressClick(policy)}
							disabled={AddressPolicyLock(policy)}
						/>
					))}
					{healthPolicies(policies).map((policy: any, index: number) => (
						<PolicyCard
							className={index < policies?.length - 1 ? 'mb-20' : ''}
							key={policy.policyNo}
							policyDetails={policy}
							changeButtonText="CHANGE_ADDRESS"
							onChangeClick={() => handleOnChangeAddressClick(policy)}
							disabled={AddressPolicyLock(policy)}
						/>
					))}
					{assetPolicies(policies).map((policy: any, index: number) => (
						<PolicyCard
							className={index < policies?.length - 1 ? 'mb-20' : ''}
							key={policy.policyNo}
							policyDetails={policy}
							changeButtonText="CHANGE_ADDRESS"
							onChangeClick={() => handleOnChangeAddressClick(policy)}
							disabled={AddressPolicyLock(policy)}
						/>
					))}
				</Grid>
			) : (
				<></>
			)}
		</Grid>
	);
};

export default CorrespondenceAddress;
